<template>
  <v-card>
    <v-card-text>
      <!-- Description -->
      <!-- <h1>ใบสั่งซื้อ</h1> -->
      <div class="d-flex justify-space-between">
        <div class="d-flex align-right" style="width: 100%">
          <v-dialog v-model="dialog" transition="dialog-top-transition" width="850">
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col
                  md="10"
                  cols="9"></v-col>
                <v-col
                  md="2"
                  cols="3">
                  <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
                    <v-icon class="me-2">
                      {{ icons.mdiPlusCircle }}
                    </v-icon>
                    สร้างใบค่าใช้จ่าย
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template>
              <v-card>
                <v-toolbar color="primary" @click="close()">
                  <p class="mb-0">
                    {{ editedIndex > -1 ? 'แก้ไขใบรค่าใช้จ่าย' : 'สร้างใบค่าใช้จ่าย' }}
                  </p>
                </v-toolbar>
                <v-card flat>
                  <v-card-text>
                    <!-- ประเภทใบสั่งซื้อ -->
                    <v-row>
                      <!--                      <v-col-->
                      <!--                        v-if="!editedItem.id"-->
                      <!--                        md="6" sm="6" cols="12">-->

                      <!--                        <v-select-->
                      <!--                          item-text="purchase_on"-->
                      <!--                          item-value="id"-->
                      <!--                          @change="getPurchaseOn()"-->
                      <!--                          v-model="editedItem.purchase_id"-->
                      <!--                          :items="purchases"-->
                      <!--                          label="ใบสั่งซื้อ"-->
                      <!--                          placeholder="ใบสั่งซื้อ"-->
                      <!--                        ></v-select>-->

                      <!--                      </v-col>-->
                      <v-col md="6" sm="6" cols="12">
                        <DatePicker
                          class="mt-5 mb-5"
                          value-type="format"
                          :disabled="editedItem.id != null && editedItem.id != ''"
                          format="YYYY-MM-DD"
                          v-model="editedItem.date"
                          placeholder="วันที่"
                          label="วันที่"
                          style="width: 100%"
                        ></DatePicker>

                        <small
                          class="mt-5 validate-err"
                          v-for="(err,index) in v$.editedItem.date.$silentErrors"
                          v-if="v$.editedItem.date.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        v-if="editedItem.id"
                        md="6" sm="6" cols="12">

                        <v-text-field
                          disabled
                          v-model="editedItem.code"
                          label="เลขที่">
                        </v-text-field>

                      </v-col>
                    </v-row>
                    <!--      <hr class="mb-3" />-->
                    <!--      <h3>ประเภทใบสั่งซื้อ</h3>-->
                    <!--      <p>กรุณาเลือกประเภทของการสั่งซื้อ เพื่อการลงบัญชีที่ถูกต้อง <a href="#">เรียนรู้เพิ่มเติม</a></p>-->
                    <!--      <v-radio-group v-model="radioGroup">-->
                    <!--        <v-radio v-for="n in dataRadio" :key="n" :label="`${n}`" :value="n"></v-radio>-->
                    <!--      </v-radio-group>-->
                    <!-- จบประเภทใบสั่งซื้อ -->

                    <hr class="mb-3 mt-3"/>


                    <!-- เงื่อนไข -->
                    <!--      <h3>เงื่อนไขราคา</h3>-->
                    <!--      <v-row class="mt-2">-->
                    <!--        &lt;!&ndash; สกุลเงิน &ndash;&gt;-->
                    <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                    <!--          <h4>สกุลเงิน</h4>-->
                    <!--        </v-col>-->
                    <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                    <!--          <v-select :items="currency" label="เลือกสกุลเงิน" outlined hide-details></v-select>-->
                    <!--        </v-col>-->
                    <!--        &lt;!&ndash; เครดิต &ndash;&gt;-->
                    <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                    <!--          <h4>เครดิต</h4>-->
                    <!--        </v-col>-->
                    <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                    <!--          <v-select :items="credit" label="เลือกเครดิต" outlined hide-details></v-select>-->
                    <!--        </v-col>-->
                    <!--        &lt;!&ndash; ภาษีมูลค่าเพิ่ม &ndash;&gt;-->
                    <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                    <!--          <h4>ภาษีมูลค่าเพิ่ม</h4>-->
                    <!--        </v-col>-->
                    <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                    <!--          <v-select :items="tax" label="เลือกภาษี" outlined hide-details></v-select>-->
                    <!--        </v-col>-->
                    <!--        &lt;!&ndash; ส่วนลด &ndash;&gt;-->
                    <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                    <!--          <h4>ส่วนลด</h4>-->
                    <!--        </v-col>-->
                    <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                    <!--          <v-select :items="discount" label="เลือกส่วนลด" outlined hide-details></v-select>-->
                    <!--        </v-col>-->
                    <!--      </v-row>-->
                    <!-- จบเงื่อนไข -->

                    <!--                    <hr class="mb-3 mt-3"/>-->

                    <!-- สินค้า -->
                    <!--                    <div class="d-flex align-center justify-space-between">-->
                    <!--                      <div>-->
                    <!--                        <h3>รายการ</h3>-->
                    <!--                        <p class="mb-0">รายการใบสั่งซื้อสินค้า Purchase Order</p>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash;                <v-dialog transition="dialog-top-transition" width="500">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                  <template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                    <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      เพิ่มโปรเจกต์&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                    </v-btn>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                  </template>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                  <template v-slot:default="dialog">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                    <v-card>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      <v-toolbar color="primary">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        <p class="mb-0">เพิ่มโปรเจกต์</p>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      </v-toolbar>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      <v-card-text class="mt-5">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        <p class="mb-2">กรอกชื่อโปรเจกต์</p>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        <v-text-field&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          v-model="projects"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          outlined&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          dense&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          hide-details&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          label="ชื่อโปรเจกต์"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          placeholder="กรอกชื่อโปรเจกต์"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                          class="mt-2 mb-3"&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        ></v-text-field>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      </v-card-text>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      <v-card-actions class="justify-end">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        <v-btn color="error" text @click="dialog.value = false">ยกเลิก</v-btn>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                        <v-btn color="primary" @click="dialog.value = false">สร้าง</v-btn>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                      </v-card-actions>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                    </v-card>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                  </template>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;                </v-dialog>&ndash;&gt;-->
                    <!--                    </div>-->
                    <v-dialog v-model="dialogAddProduct" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="closeAddProduct"
                          style="float: right" color="secondary" dark class="mb-3" v-bind="attrs"
                          v-on="on">
                          เพิ่มรายการ
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">เพิ่มรายการ</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <!--                              <v-col cols="12" sm="12" md="12">-->
                              <!--                                <v-select-->
                              <!--                                  @change="getPurchaseProduct(amount_product,type)"-->
                              <!--                                  v-model="amount_product"-->
                              <!--                                  :items="[1,5,10,20,50,100,500,1000]"-->
                              <!--                                  label="สินค้าน้อยกว่า">-->

                              <!--                                </v-select>-->
                              <!--                              </v-col>-->

                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  min="1"
                                  v-model="itemCost.name"
                                  label="รายการ"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  min="1"
                                  type="number"
                                  v-model="itemCost.price"
                                  label="ราคา"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  min="1"
                                  v-model="itemCost.amount"
                                  type="number"
                                  label="จำนวน"></v-text-field>
                              </v-col>
                              <!--                              <v-col cols="12" sm="12" md="12">-->
                              <!--                                <v-radio-group-->
                              <!--                                  v-model="type"-->
                              <!--                                  @change="getPurchaseProduct(amount_product,type)"-->
                              <!--                                  label="ประเภทสินค้า">-->
                              <!--                                  <v-radio value="1" label="ออนไลน์"></v-radio>-->
                              <!--                                  <v-radio value="2" label="ภายใน"></v-radio>-->
                              <!--                                </v-radio-group>-->
                              <!--                                <span style="color: red;">*ใช้ในกรณีค้นสินค้าเพื่อเพิ่มรายการสินค้า</span>-->
                              <!--                              </v-col>-->

                              <!--                              <v-col cols="12" sm="12" md="12">-->
                              <!--                                <v-select-->
                              <!--                                  v-model="product_id"-->
                              <!--                                  label="ค้นหาสินค้า"-->
                              <!--                                  item-value="id"-->
                              <!--                                  item-text="product_name"-->
                              <!--                                  :items="purchase_products">-->
                              <!--                                </v-select>-->
                              <!--                                <span style="color: red;">*ใช้ในกรณีค้นสินค้าเพื่อเพิ่มรายการสินค้า</span>-->
                              <!--                              </v-col>-->
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="closeAddProduct">
                            ยกเลิก
                          </v-btn>
                          <v-btn color="primary" text

                                 @click="selectProduct">
                            เพิ่มรายการ
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <table class="table table-hover" style="width: 100%">
                      <thead>
                      <tr>
                        <!--          <th scope="col" style="text-align: left;width: 5%">-->
                        <!--            <br>-->
                        <!--            &lt;!&ndash;              <input v-model="selectAll" type="checkbox"&ndash;&gt;-->
                        <!--            &lt;!&ndash;                         title="เลือกทั้งหมด"&ndash;&gt;-->
                        <!--            &lt;!&ndash;                         v-on:click="selectAllFunction">&ndash;&gt;-->
                        <!--          </th>-->
                        <th scope="col" style="text-align: center;">#</th>
                        <th scope="col" style="text-align: center">
                          รายการ
                        </th>
                        <th scope="col" style="text-align: left;width: 10%">ราคา</th>
                        <th scope="col" style="text-align: left;width: 10%">จำนวน</th>
                        <th scope="col" style="text-align: right;width: 10%">รวม</th>
                        <th scope="col" style="text-align: center;width: 10%">เครื่องมือ
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(list,index) in details">
                        <!--          <td><input v-if="list.product_id" v-model="selected" title="เลือก"-->
                        <!--                     :value="list.product_id" type="checkbox">-->
                        <!--          </td>-->
                        <td style="text-align: center">{{ index + 1 }}</td>
                        <td>
                          {{ list.name}}
                        </td>
                        <td>

                          <v-text-field
                            v-model="list.price"
                            placeholder="กรุณากรอกราคา"
                            min="1"
                            inline
                            type="number"></v-text-field>

                          <!--            <span v-if="!list.product_costs"-->
                          <!--                  v-bind:style="list.product_costs ? '' : 'color: red;'">*กรุณากรอกราคา</span>-->
                        </td>
                        <td>
                          <v-text-field
                            type="number"
                            min="1"
                            v-model="list.amount"
                            placeholder="จำนวน"
                          ></v-text-field>
                        </td>
                        <td>
                          {{list.price * list.amount}}
                        </td>
                        <td>
                          <v-btn
                            v-if="editedItem.status != 'Y' && details && details.length > 0"
                            @click="deleteItemProduct(index)">
                            ลบ
                          </v-btn>

                          <!--            <button v-if="!list.product_id" type="button"-->
                          <!--                    :disabled="list.disabled"-->
                          <!--                    class="btn btn-warning" v-on:click="addProduct(index)"-->
                          <!--                    title="เพิ่มสินค้า">-->
                          <!--              เพิ่มสินค้า <i class="fas fa-plus-circle"></i>-->
                          <!--            </button>-->
                        </td>
                      </tr>
                      <tr v-if="details && details.length > 0"
                          style="background-color: black;color: white;font-weight: 700;font-size: 24px">
                        <th style="text-align: right" colspan="1"></th>
                        <th style="text-align: right;;color: white" colspan="4">
                          <h4
                            style="color: white">
                            จำนวนรายการ
                          </h4>
                        </th>
                        <th style="text-align: right;color: white">
                          <h3 class="text-center" style="color: white">
                            {{ getTotal(1) }}
                          </h3>
                        </th>
                      </tr>
                      <tr v-if="details && details.length > 0"
                          style="background-color: black;color: white;font-weight: 700;font-size: 24px">
                        <th style="text-align: right" colspan="1"></th>
                        <th style="text-align: right;;color: white" colspan="4">
                          <h4
                            style="color: white">
                            จำนวนเงินรวมทั้งสิ้น
                          </h4>
                        </th>
                        <th style="text-align: right;color: white">
                          <h3 class="text-center" style="color: white">
                            {{ getTotal(2) }}
                          </h3>
                        </th>
                      </tr>
                      </tbody>
                    </table>
                    <!--      <v-data-table :headers="headers" :items="editedItem.details" sort-by="calories" class="elevation-1 mt-4">-->
                    <!--        <template v-slot:top>-->
                    <!--          <v-toolbar flat>-->
                    <!--            <v-divider class="mx-4" inset vertical></v-divider>-->
                    <!--            <v-spacer></v-spacer>-->
                    <!--            <v-dialog v-model="dialog" max-width="500px">-->
                    <!--              <template v-slot:activator="{ on, attrs }">-->
                    <!--                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">-->
                    <!--                  เพิ่มรายการสินค้าสินค้า-->
                    <!--                </v-btn>-->
                    <!--              </template>-->
                    <!--              <v-card>-->
                    <!--                <v-card-title>-->
                    <!--                  <span class="text-h5">{{ formTitle }}</span>-->
                    <!--                </v-card-title>-->

                    <!--                <v-card-text>-->
                    <!--                  <v-container>-->
                    <!--                    <v-row>-->
                    <!--                      <v-col cols="12" sm="12" md="12">-->
                    <!--                        <v-select-->
                    <!--                          @change="getPurchaseProduct(amount_product,type)"-->
                    <!--                          v-model="amount_product"-->
                    <!--                          :items="[1,5,10,20,50,100,500,1000]"-->
                    <!--                          label="สินค้าน้อยกว่า">-->

                    <!--                        </v-select>-->
                    <!--                      </v-col>-->
                    <!--                      <v-col cols="12" sm="12" md="12">-->
                    <!--                        <v-radio-group-->
                    <!--                          v-model="type"-->
                    <!--                          @change="getPurchaseProduct(amount_product,type)"-->
                    <!--                          label="ประเภทสินค้า">-->
                    <!--                          <v-radio value="1" label="ออนไลน์"></v-radio>-->
                    <!--                          <v-radio value="2" label="ภายใน"></v-radio>-->
                    <!--                        </v-radio-group>-->
                    <!--                      </v-col>-->

                    <!--                      <v-col cols="12" sm="12" md="12">-->
                    <!--                        <v-select-->
                    <!--                          v-model="product_id"-->
                    <!--                          label="ค้นหาสินค้า"-->
                    <!--                          item-value="id"-->
                    <!--                          item-text="product_name"-->
                    <!--                          :items="purchase_products">-->
                    <!--                        </v-select>-->
                    <!--                      </v-col>-->
                    <!--                    </v-row>-->
                    <!--                  </v-container>-->
                    <!--                </v-card-text>-->

                    <!--                <v-card-actions>-->
                    <!--                  <v-spacer></v-spacer>-->
                    <!--                  <v-btn color="error" text @click="close">-->
                    <!--                    Cancel-->
                    <!--                  </v-btn>-->
                    <!--                  <v-btn color="primary" text @click="selectProduct">-->
                    <!--                    Save-->
                    <!--                  </v-btn>-->
                    <!--                </v-card-actions>-->
                    <!--              </v-card>-->
                    <!--            </v-dialog>-->
                    <!--            <v-dialog v-model="dialogDelete" max-width="500px">-->
                    <!--              <v-card>-->
                    <!--                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>-->
                    <!--                <v-card-actions>-->
                    <!--                  <v-spacer></v-spacer>-->
                    <!--                  <v-btn color="error" text @click="closeDelete">Cancel</v-btn>-->
                    <!--                  <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>-->
                    <!--                  <v-spacer></v-spacer>-->
                    <!--                </v-card-actions>-->
                    <!--              </v-card>-->
                    <!--            </v-dialog>-->
                    <!--          </v-toolbar>-->
                    <!--        </template>-->
                    <!--        <template v-slot:item.actions="{ item }">-->
                    <!--          <v-icon small class="mr-2" @click="editItem(item)">-->
                    <!--            {{ icons.mdiPencil }}-->
                    <!--          </v-icon>-->
                    <!--          <v-icon small @click="deleteItem(item)">-->
                    <!--            {{ icons.mdiDelete }}-->
                    <!--          </v-icon>-->
                    <!--        </template>-->
                    <!--        <template v-slot:item.index="{ index }">-->
                    <!--         {{index +1 }}-->
                    <!--        </template>-->
                    <!--        <template v-slot:no-data>-->
                    <!--          <v-btn color="primary" @click="initialize">-->
                    <!--            Reset-->
                    <!--          </v-btn>-->
                    <!--        </template>-->
                    <!--      </v-data-table>-->
                    <!--      <p class="text-end mb-0 mt-3 font-weight-bold">-->
                    <!--        ยอดรวม <span class="font-weight-regular ms-5">{{ editedItem.sub_total }}</span>-->
                    <!--      </p>-->
                    <!--      <p class="text-end mb-0 font-weight-bold">-->
                    <!--        ยอดเงินสุทธิ <span class="font-weight-regular ms-5">{{ editedItem.total }}</span>-->
                    <!--      </p>-->
                    <!--      <v-row>-->
                    <!--        <v-col cols="12" sm="6" md="4">-->
                    <!--          <p class="mb-0">หมายเหตุเอกสาร</p>-->
                    <!--        </v-col>-->
                    <!--        <v-col cols="12" sm="6" md="8">-->
                    <!--          <p @click="showTextarea" class="mb-0 pTagDynamic">เพิ่มหมายเหตุ</p>-->
                    <!--          <v-textarea v-if="textAreaContent" outlined hide-details label="เพิ่มหมายเหตุ"-->
                    <!--                      :value="note"></v-textarea>-->
                    <!--        </v-col>-->
                    <!--      </v-row>-->
                    <!-- จบสินค้า -->

                    <hr class="mt-3 mb-3"/>

                    <!-- เอกสาร และโน้ต-->
<!--                    <h3>โน้ต</h3>-->
                    <v-row class="mt-2">
                      <v-col cols="12" sm="6" md="4">
                        <h4 class="mb-0 text-end">โน้ต</h4>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                        <v-textarea
                          v-model="editedItem.note"
                          outlined hide-details label="โน้ตอื่น ๆ เพื่อใช้ในบริษัท"></v-textarea>
                        <!--          round-->
                        <!--          <v-btn color="primary" class="text-none mt-3" depressed :loading="isSelecting"-->
                        <!--                 @click="onButtonClick">-->
                        <!--            <v-icon left>-->
                        <!--              {{ icons.mdiCloudUpload }}-->
                        <!--            </v-icon>-->
                        <!--            {{ buttonText }}-->
                        <!--          </v-btn>-->
                        <!--                      <input ref="uploader" class="d-none" type="file" @change="onFileChanged"/>-->
                      </v-col>
                    </v-row>
                    <!-- จบเอกสาร และโน๊ต -->
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn color="error" text @click="close()">ยกเลิก</v-btn>
                    <v-btn
                      v-if="editedItem.status != 'Y' && details && details.length > 0"
                      title="บันทึกร่าง"
                      color="secondary" @click="save('draft')">บันทึกฉบับร่าง
                    </v-btn>

                    <v-btn color="primary"
                           v-if="editedItem.status != 'Y' && details && details.length > 0"
                           @click="save('save')">บันทึก
                    </v-btn>

                  </v-card-actions>

                </v-card>
                <!--                <CreateOrder-->

                <!--                  :purchase_products="purchase_products"-->
                <!--                  :getPurchaseOrder="getPurchaseOrder"-->
                <!--                  :getPurchaseProduct="getPurchaseProduct"-->
                <!--                  :close="close"-->
                <!--                  :save="save"-->
                <!--                  :v$="v$"-->
                <!--                  :payments="payments"-->
                <!--                  :data="editedItem"/>-->
              </v-card>
              <!--              <v-card>-->
              <!--                <v-card-text class="pb-0 pt-5 bg-header">-->
              <!--                  <div class="d-flex justify-space-between">-->
              <!--                    <p>สร้างใบรับสินค้า</p>-->
              <!--                  </div>-->
              <!--                </v-card-text>-->
              <!--                &lt;!&ndash; Content import &ndash;&gt;-->

              <!--                <PickupBill-->
              <!--                  :purchases="purchases"-->
              <!--                  :purchase_products="purchase_products"-->
              <!--                  :getPurchaseOrder="getPurchaseOrder"-->
              <!--                  :close="close"-->
              <!--                  :payments="payments"-->
              <!--                  :data="editedItem"/>-->
              <!--                <v-card-actions class="justify-end">-->
              <!--                  <v-btn color="error" text @click="dialog.value = false">ยกเลิก</v-btn>-->
              <!--                  <v-btn color="primary" @click="save">บันทึก</v-btn>-->
              <!--                </v-card-actions>-->
              <!--              </v-card>-->
            </template>
          </v-dialog>


          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <v-row class="mt-5">
        <v-col md="4" sm="6" cols="12">
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหา"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            v-model="status"
            :prepend-inner-icon="icons.mdiFilter"
            dense
            outlined
            hide-details
            label="ตัวกรอง"
            item-text="label"
            item-value="value"
            :items="filter"
          ></v-select>
        </v-col>
        <!--        <v-col md="4" sm="6" cols="12">-->
        <!--          <v-select-->
        <!--            :prepend-inner-icon="icons.mdiFileDocument"-->
        <!--            dense-->
        <!--            hide-details-->
        <!--            outlined-->
        <!--            item-text="label"-->
        <!--            item-value="value"-->
        <!--            label="ใบรับสินค้า"-->
        <!--            :items="filterBill"-->
        <!--          ></v-select>-->
        <!--        </v-col>-->
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="data_costs"
        :server-items-length="total"
        :options.sync="serverOptions"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="product"
        class="elevation-1 mt-5"
      >
        <!--        <template v-slot:top>-->
        <!--          <v-dialog max-width="800px">-->
        <!--            <v-card>-->
        <!--              <v-card-text class="pb-0 pt-5 bg-header">-->
        <!--                <div class="d-flex justify-space-between">-->
        <!--                  <p>สร้างใบรับสินค้า</p>-->
        <!--                </div>-->
        <!--              </v-card-text>-->

        <!--              <PickUpData />-->

        <!--              <v-card-actions class="mt-3">-->
        <!--                <v-spacer></v-spacer>-->
        <!--                <v-btn color="error" text @click="close">-->
        <!--                  Cancel-->
        <!--                </v-btn>-->
        <!--                <v-btn color="primary" @click="save">-->
        <!--                  Save-->
        <!--                </v-btn>-->
        <!--              </v-card-actions>-->
        <!--            </v-card>-->
        <!--          </v-dialog>-->
        <!--        </template>-->

        <template v-slot:item.index="{ index }">
          {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
        </template>
        <template v-slot:item.status="{ item }">
          <span class="warning-badge" v-if="item.status == 'N'">ฉบับร่าง</span>
<!--          <span class="danger-badge" v-if="item.status == 'W'">สินค้าค้างรับ</span>-->
          <span class="success-badge" v-if="item.status == 'Y'">เรียบร้อย</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <!--          <v-icon-->
          <!--            small-->
          <!--            @click="print(item)"-->
          <!--            class="mr-2"-->
          <!--          >-->
          <!--            {{ icons.mdiPrinter }}-->
          <!--          </v-icon>-->
          <v-icon @click="editItem(item)" size="17">{{ icons.mdiPencil }}</v-icon>

          <v-icon
            @click="deleteItem(item)"
            small>{{ icons.mdiDelete }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiCartMinus,
  mdiMagnify,
  mdiTruck,
  mdiPrinterSearch,
  mdiPlusCircle,
  mdiFilter,
  mdiFileDocument,
  mdiPencil,
  mdiDelete,
  mdiPrinter
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import ModalUploadFile from './Modal_uploadFile.vue'
import PickUpData from './table_data/PickUpData.vue'
import instance_pickup from '../../../services/purchaseOrder'
import instance_data_cost from '../../../services/dataCost'
import CreateOrder from '../page-createBill/CreateOrder.vue'
import instance_payments from '../../../services/payment'
import PickupBill from "@/views/pages/page-createBill/PickupBill";
import {useVuelidate} from "@vuelidate/core";
import {minLength, required} from "@vuelidate/validators";
import Swal from "sweetalert2";

export default {
  components: {
    PickupBill,
    DatePicker,
    ModalUploadFile,
    mdiPencil,
    mdiDelete,
    CreateOrder,
    PickUpData,
    mdiPrinter
  },
  setup() {
    const filter = [
      {
        label: 'ทั้งหมด',
        value: ''
      },
      {
        label: 'ฉบับร่าง',
        value: 'N'
      },
      {
        label: 'เรียบร้อย',
        value: 'Y'
      }]
    const filterBill = ['ใบรับสินค้า', 'ชื่อซัพพลายเออร์', 'สินค้า', 'ชื่อโปรเจกต์', 'โน็ต', 'เลขที่ใบกำกับภาษี']

    return {
      v$: useVuelidate(),
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
        mdiPlusCircle,
        mdiFilter,
        mdiFileDocument,
        mdiPencil,
        mdiDelete,
        mdiPrinter
      },
      filter,
      filterBill,
    }
  },
  data() {
    return {
      serverOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      total: 0,
      status: '',
      purchase: {},
      sortBy: '',
      sortDesc: '',
      details: [],
      amount_product: '',
      product_id: '',
      type: '',
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      selected: [],
      payments: [],
      purchases: [],
      purchase_products: [],
      editedIndex: -1,
      search: '',
      dialog: false,
      dialogDelete: false,
      dialogAddProduct: false,
      headers: [
        {text: 'ลำดับ', value: 'index', sortable: false},
        {text: 'วันที่', value: 'date'},
        // {text: 'เลขที่ใบสั่งซื้อสินค้า', value: 'purchase_on'},
        {text: 'จำนวนรายการ', value: 'amount'},
        {text: 'ยอดรวม', value: 'total'},
        {text: 'พนักงาน', value: 'employee_name'},
        {text: 'สถานะ', value: 'status'},
        //     data: "status", render: function (status) {
        //   let type = '{{$type}}'
        //   if (type === 'ใบรับสินค้า Goods Receipt') {
        //     if (status  === 'Y') {
        //       return '<span style="color:green;">ปรับยอดเรียบร้อย</span>'
        //     }  else if(status  === 'W') {
        //
        //       return '<span style="color:red;">สินค้าค้างรับ</span>';
        //     }
        //     return '<span style="color:orange;">ฉบับร่าง</span>'
        //   } else {
        //     if (status  === 'Y') {
        //       return '<span>-</span>'
        //     }   {
        //       return '<span style="color:orange;">ฉบับร่าง</span>'
        //     }
        //   }
        // }
        {text: 'เครื่องมือ', value: 'actions', sortable: false},
      ],
      data_costs: [],

      editedItem: {
        id : '',
        date : this.nowDate(),
        details : [],
        note : ''
      },
      defaultItem: {
        id : '',
        date : this.nowDate(),
        details : [],
        note : ''
      },
      itemCost: {
        name : '',
        price : '',
        amount : '',
      },
      defaultItemCost: {
        name : '',
        price : '',
        amount : ''
      },
    }
  },
  validations() {
    return {
      editedItem: {
        date: {required},
      },
      details: {
        required,
        minLength: minLength(1)
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    serverOptions: {
      handler() {
        this.getDataCost()
      },
    },
    search: {
      handler() {
        this.getDataCost()
      },
    },
    status: {
      handler() {
        this.getDataCost()
      },
    },
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    getTotal(status){
      var total = 0;
      for(var i =0;i< this.details.length;i++){
        if (status == 1) {

          total+= (this.details[i].amount)
        }else{

          total+= (this.details[i].price*this.details[i].amount)
        }
      }
      return total
    },
    subTotal: function () {
      let total = [];
      Object.entries(this.details).forEach(([key, val]) => {
        let sum = 0;
        if (val.product_id) {
          let product_costs = val.product || val.product_costs ? (val.product ? val.product.product_costs : val.product_costs) : 0;
          let amount = val.amount;
          let discount = parseFloat(val.discount);
          if ((product_costs * amount) > discount) {
            sum = (product_costs * amount) - discount
          } else {
            sum = (product_costs * amount)
          }
        }

        total.push(parseFloat(sum).toFixed(2));
      });

      return total.reduce(function (total, num) {
        return parseFloat(total) + parseFloat(num)
      }, 0);

    },
    afterDiscount: function () {
      let subTotal = this.subTotal();
      let discount = this.editedItem.discount;
      return parseFloat(subTotal - discount).toFixed(2);
    }
    ,
    VatTotal: function () {
      let afterDiscount = this.afterDiscount();
      let vat = parseFloat((afterDiscount * 7) / 100).toFixed(2);
      return parseFloat(afterDiscount - vat).toFixed(2);
    },
    nowDate() {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      var d = `${year}-${String(month).padStart(2, '0')}-${day}`;
      return d;
    },
    getDataCost() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        purchase_order_type: 2,
        status: this.status,
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }
      instance_data_cost.get(data).then(res => {
        this.data_costs = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },
    getPickupList() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        purchase_order_type: 2
      }
      instance_pickup.getAll(data).then(res => {
        this.purchases = res.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },
    getPurchaseProduct() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        type: 2
      }
      instance_purchase_order.getPurchaseProduct(data).then(res => {
        this.purchase_products = res.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },
    getPayment() {
      instance_payments.get().then(res => {
        this.payments = res.data.data
      }).catch(err => {

      })
    },
    initialize() {
      this.pickups = []
    },
    getPurchaseOrder() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        purchase_order_type: 2
      }
      instance_purchase_order.get(data).then(res => {
        this.purchase_orders = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },
    print(item) {
      var url = `/order_print/${item.id}?purchase_order_type=2`
      window.open(url, '_blank', 'noreferrer');
    },
    editItem(item) {
      this.editedIndex = this.data_costs.indexOf(item)
      this.editedItem = {...item}
      instance_data_cost.show(this.editedItem.id)
        .then(res => {
          // this.editedItem = res.data.purchase_orders;
          this.details = res.data.data.details;

          this.dialog = true;
        }).catch(err => {

      })
    },
    deleteItem(item) {
      this.editedIndex = this.pickups.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    deleteItemProduct(index) {
      this.details.splice(index, 1)
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_data_cost.destroy(this.editedItem.id).then(res => {
        this.getDataCost()
        this.closeDelete()
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },
    closeAddProduct() {

      this.$nextTick(() => {
        this.itemCost = Object.assign({}, this.defaultItemCost)
        // this.editedIndex = -1
      })
      this.dialogAddProduct = false;
    },
    close() {
      this.v$.$reset()
      this.dialog = false
      this.details = []
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    selectProduct(item) {

      this.details.push(this.itemCost)
      // this.getTotal();
      this.dialogAddProduct = false;

    },
    async save(status) {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true
      if (status != 'draft'){
        status = "Y"
      }else{
        status = "N"
      }

      this.editedItem.status = status;
      this.editedItem.details = this.details;


      if (this.editedIndex > -1) {
        instance_data_cost.update(this.editedItem, status).then(res => {
          if (res.data.success) {
            this.getDataCost();
            this.close()
          } else {
            Swal.fire({
              icon: 'error',
              text: res.data.messages,
              showConfirmButton: false,
              timer: 1500
            })
            this.$store.state.isLoading = false
          }
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            text: err.message,
            showConfirmButton: false,
            timer: 1500
          })
          this.$store.state.isLoading = false
        })
      } else {
        this.editedItem.create = true;

        instance_data_cost.store(this.editedItem, status).then(res => {
          if (res.data.success) {
            this.getDataCost();
            this.close()
          } else {
            Swal.fire({
              icon: 'error',
              text: res.data.messages,
              showConfirmButton: false,
              timer: 1500
            })
            this.$store.state.isLoading = false
          }
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            text: err.message,
            showConfirmButton: false,
            timer: 1500
          })
          this.$store.state.isLoading = false

        })

      }
    },
  },
  mounted() {
    this.getDataCost();
    // this.getPickupList();
    // this.getPurchaseProduct();
    // this.getPayment();
  }
}
</script>

<style scoped>
.box_content {
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.box_content.box-1 {
  background-color: #8dbc89;
  color: #ffffff;
  width: 21rem;
  height: 5rem;
}

.box_content.box-2 {
  background-color: dodgerblue;
  color: #ffffff;
  width: 10rem;
  height: 5rem;
}

.box_content.box-3 {
  color: #ffffff;
  background-color: coral;
  width: 10rem;
  height: 5rem;
}

.bg-header {
  background-color: #8dbc89;
}

.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}

.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}

.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}

.picker-res {
  width: 100% !important;
}

.test {
  display: flex;
}

.pClick {
  cursor: pointer;
  color: brown;
}

@media screen and (max-width: 600px) {
  .test {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .test2 {
    display: grid !important;
  }

  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-40%);
    left: 50%;
  }

  /* .picker-res {
    width: 100% !important;
  } */
  .box_content.box-1 {
    width: 5rem !important;
  }

  .box_content.box-2 {
    width: 5rem !important;
  }

  .box_content.box-3 {
    width: 5rem !important;
  }
}

@media screen and (max-width: 960px) {
  .box_content.box-1 {
    width: 8rem;
  }

  .box_content.box-2 {
    width: 8rem;
  }

  .box_content.box-3 {
    width: 8rem;
  }
}
</style>
