import api from '../axios.service'

export default {
  get(data) {
    return api.get(`data_cost/list?search=${data.search}&purchase_order_type=${data.purchase_order_type}&size=${data.size}&page=${data.page}&status=${data.status}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getAll(data) {
    return api.get(`data_cost/all?search=${data.search}&purchase_order_type=${data.purchase_order_type}`)
  },
  getPurchaseProduct(data) {
    return api.get(`data_cost/getPurchaseProduct?type=${data.type}&amount=${data.amount}`)
  },
  // eslint-disable-next-line camelcase
  show(id,purchase_order_type = '') {
    // eslint-disable-next-line camelcase
    return api.get(`data_cost/show/${id}`)
  },
  getPurchaseOrder(id,amount,type){
    return api.get(`data_cost/get_purchase_order/${id}?amount=${amount}&type=${type}`)
  },
  getPurchaseOrderDetail(id,purchase_order_type = 1){
    return api.get(`data_cost/show/${id}?purchase_order_type=${purchase_order_type}`)
  },
  store(data){
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'details'){
          params.append('details', JSON.stringify(getFormData[key]));
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`data_cost/store`,params,config)
  },
  update(data){
    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'details'){
          params.append('details', JSON.stringify(getFormData[key]));
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`data_cost/update/${data.id}`,params,config)
  },
  destroy(id){

    let params = new FormData();
    params.append('_method', "DELETE");
    return api.post(`data_cost/delete/${id}`, params, {})
  },


}
