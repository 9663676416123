<template>
  <v-card flat>
    <v-card-text>
      <!-- ประเภทใบสั่งซื้อ -->
      <v-row>
        <v-col md="6" sm="6" cols="12">
          <v-select
              item-text="purchase_on"
              item-value="id"
              :items="purchases"
              label="ใบสั่งซื้อ"
              placeholder="ใบสั่งซื้อ"
          ></v-select>

        </v-col>
        <v-col md="6" sm="6" cols="12">
          <Datepicker
            class="mt-5"
              title="วันที่"
              placeholder="วันที่"
              value-type="format"
              format="YYYY-MM-DD"
          label="วันที่"></Datepicker>
<!--          <v-menu-->
<!--            ref="menu"-->
<!--            v-model="editedItem.purchase_on"-->
<!--            :close-on-content-click="false"-->
<!--            :return-value.sync="date"-->
<!--            transition="scale-transition"-->
<!--            offset-y-->
<!--            min-width="auto"-->
<!--          >-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--&lt;!&ndash;              <v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="date"&ndash;&gt;-->
<!--&lt;!&ndash;                label="วันที่"&ndash;&gt;-->
<!--&lt;!&ndash;                prepend-icon="mdi-calendar"&ndash;&gt;-->
<!--&lt;!&ndash;                readonly&ndash;&gt;-->
<!--&lt;!&ndash;                v-bind="attrs"&ndash;&gt;-->
<!--&lt;!&ndash;                v-on="on"&ndash;&gt;-->
<!--&lt;!&ndash;              ></v-text-field>&ndash;&gt;-->

<!--              <DatePicker-->
<!--                v-model="editedItem"-->
<!--                placeholder="วันที่"-->
<!--                label="วันที่"-->
<!--              ></DatePicker>-->
<!--&lt;!&ndash;              <v-date-picker></v-date-picker>&ndash;&gt;-->
<!--            </template>-->
<!--            <v-date-picker v-model="editedItem.date" no-title scrollable>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn text color="primary" @click="menu = false">-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--              <v-btn text color="primary" @click="$refs.menu.save(date)">-->
<!--                OK-->
<!--              </v-btn>-->
<!--            </v-date-picker>-->
<!--          </v-menu>-->
        </v-col>
      </v-row>
<!--      <hr class="mb-3" />-->
<!--      <h3>ประเภทใบสั่งซื้อ</h3>-->
<!--      <p>กรุณาเลือกประเภทของการสั่งซื้อ เพื่อการลงบัญชีที่ถูกต้อง <a href="#">เรียนรู้เพิ่มเติม</a></p>-->
<!--      <v-radio-group v-model="radioGroup">-->
<!--        <v-radio v-for="n in dataRadio" :key="n" :label="`${n}`" :value="n"></v-radio>-->
<!--      </v-radio-group>-->
      <!-- จบประเภทใบสั่งซื้อ -->

      <hr class="mb-3" />

      <!-- ซัพพลายเออร์ -->
      <v-row>
        <v-col md="6" sm="6" cols="12">
          <h3>ซัพพลายเออร์</h3>
          <p class="mb-0">เพิ่มรายละเอียดของซัพพลายเออร์ <a href="#">เรียนรู้เพิ่มเติม</a></p>
        </v-col>
        <v-col md="6" sm="6" cols="12" class="d-flex justify-end">
          <v-dialog transition="dialog-top-transition" width="700">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">
                <v-icon>{{ icons.mdiMagnify }}</v-icon>
                ข้อมูลกรมสรรพากร
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary">
                  <p class="mb-0">ค้นหาข้อมุลบริษัทจากฐานข้อมูลกรมสรรพากร</p>
                </v-toolbar>
                <RevenueDepartment />
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <!-- ชื่อซัพพลายเออร์ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ชื่อซัพพลายเออร์</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="editedItem.supplier_name"
            outlined
            dense
            hide-details
            label="กรอกชื่อซัพพลายเออร์"
            placeholder="เพิ่มชื่อซัพพลายเออร์ในนามบุคคล หรือ นิติบุคคล"
            class="mt-2 mb-3"
          ></v-text-field>
        </v-col>
        <!-- ที่อยู่ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ที่อยู่</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-textarea
            v-model="editedItem.address"
            outlined hide-details label="เพิ่มที่อยู่"></v-textarea>
        </v-col>
        <!-- เลขประจำตัวผู้เสียภาษี -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-1 pb-0">
          <h4 class="text-end">เลขประจำตัวผู้เสียภาษี</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-1 pb-0">
          <v-text-field
            v-model="editedItem.tax_id"
            outlined
            dense
            hide-details
            label="กรอกเลขประจำตัวผู้เสียภาษี"
            placeholder="เลขประจำตัวผู้เสียภาษี"
            class="mt-2 mb-3"
          ></v-text-field>
        </v-col>
        <!-- ที่อยู่ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ชื่อโปรเจ็ค</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="editedItem.project_name"
            outlined
            dense
            hide-details
            label="ชื่อโปรเจ็ค"
            placeholder="ชื่อโปรเจ็ค"
            class="mt-2 mb-3"
          ></v-text-field>
<!--          <v-radio-group v-model="radioGroup2">-->
<!--            <v-radio v-for="n in buyForm" :key="n" :label="`${n}`" :value="n"></v-radio>-->
<!--          </v-radio-group>-->
        </v-col>
        <!-- ชื่อซัพพลายเออร์ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ชื่อผู้ติดต่อ</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="editedItem.contact_name"
            outlined
            dense
            hide-details
            label="ชื่อผู้ติดต่อ"
            placeholder="ชื่อผู้ติดต่อ"
            class="mt-2 mb-3"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ประเภทการชำระเงิน</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-select
            v-model="editedItem.payment_id"
            :items="payments"
            item-value="id"
            item-text="name"
          ></v-select>
        </v-col>
        <!-- ข้อมูลผู้ติดต่อ -->
<!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">-->
<!--          <h4 class="text-end">ข้อมูลผู้ติดต่อ</h4>-->
<!--        </v-col>-->
<!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">-->
<!--          <p v-if="!showData" @click="clickShowData" class="mb-0 pTagDynamic">แสดงข้อมูล</p>-->
<!--          <p v-if="showData" @click="clickShowData" class="mb-0 pTagDynamic">ซ่อนข้อมูล</p>-->
<!--          <div v-if="showData">-->
<!--            <v-text-field-->
<!--              v-model="contactPerson"-->
<!--              outlined-->
<!--              dense-->
<!--              hide-details-->
<!--              label="ผู้ติดต่อ"-->
<!--              placeholder="กรอกผู้ติดต่อ"-->
<!--              class="mt-2 mb-3"-->
<!--            ></v-text-field>-->
<!--            <v-text-field-->
<!--              v-model="emailPerson"-->
<!--              outlined-->
<!--              dense-->
<!--              hide-details-->
<!--              label="E-mail"-->
<!--              placeholder="กรอก Email"-->
<!--              class="mt-2 mb-3"-->
<!--            ></v-text-field>-->
<!--            <v-text-field-->
<!--              v-model="telPerson"-->
<!--              outlined-->
<!--              dense-->
<!--              hide-details-->
<!--              label="โทรศัพท์"-->
<!--              placeholder="กรอกเบอร์โทรศัพท์"-->
<!--              class="mt-2 mb-3"-->
<!--            ></v-text-field>-->
<!--          </div>-->
<!--        </v-col>-->
      </v-row>
      <!-- จบซัพพลายเออร์ -->

      <hr class="mb-3 mt-8" />

      <!-- เงื่อนไข -->
<!--      <h3>เงื่อนไขราคา</h3>-->
<!--      <v-row class="mt-2">-->
<!--        &lt;!&ndash; สกุลเงิน &ndash;&gt;-->
<!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
<!--          <h4>สกุลเงิน</h4>-->
<!--        </v-col>-->
<!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
<!--          <v-select :items="currency" label="เลือกสกุลเงิน" outlined hide-details></v-select>-->
<!--        </v-col>-->
<!--        &lt;!&ndash; เครดิต &ndash;&gt;-->
<!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
<!--          <h4>เครดิต</h4>-->
<!--        </v-col>-->
<!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
<!--          <v-select :items="credit" label="เลือกเครดิต" outlined hide-details></v-select>-->
<!--        </v-col>-->
<!--        &lt;!&ndash; ภาษีมูลค่าเพิ่ม &ndash;&gt;-->
<!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
<!--          <h4>ภาษีมูลค่าเพิ่ม</h4>-->
<!--        </v-col>-->
<!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
<!--          <v-select :items="tax" label="เลือกภาษี" outlined hide-details></v-select>-->
<!--        </v-col>-->
<!--        &lt;!&ndash; ส่วนลด &ndash;&gt;-->
<!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
<!--          <h4>ส่วนลด</h4>-->
<!--        </v-col>-->
<!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
<!--          <v-select :items="discount" label="เลือกส่วนลด" outlined hide-details></v-select>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <!-- จบเงื่อนไข -->

      <hr class="mb-3 mt-3" />

      <!-- สินค้า -->
      <div class="d-flex align-center justify-space-between">
        <div>
          <h3>รายการ</h3>
          <p class="mb-0">รายการใบสั่งซื้อสินค้า Purchase Order</p>
        </div>
<!--        <v-dialog transition="dialog-top-transition" width="500">-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">-->
<!--              เพิ่มโปรเจกต์-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <template v-slot:default="dialog">-->
<!--            <v-card>-->
<!--              <v-toolbar color="primary">-->
<!--                <p class="mb-0">เพิ่มโปรเจกต์</p>-->
<!--              </v-toolbar>-->
<!--              <v-card-text class="mt-5">-->
<!--                <p class="mb-2">กรอกชื่อโปรเจกต์</p>-->
<!--                <v-text-field-->
<!--                  v-model="projects"-->
<!--                  outlined-->
<!--                  dense-->
<!--                  hide-details-->
<!--                  label="ชื่อโปรเจกต์"-->
<!--                  placeholder="กรอกชื่อโปรเจกต์"-->
<!--                  class="mt-2 mb-3"-->
<!--                ></v-text-field>-->
<!--              </v-card-text>-->
<!--              <v-card-actions class="justify-end">-->
<!--                <v-btn color="error" text @click="dialog.value = false">ยกเลิก</v-btn>-->
<!--                <v-btn color="primary" @click="dialog.value = false">สร้าง</v-btn>-->
<!--              </v-card-actions>-->
<!--            </v-card>-->
<!--          </template>-->
<!--        </v-dialog>-->
      </div>
      <v-data-table :headers="headers" :items="editedItem.details" sort-by="calories" class="elevation-1 mt-4">
        <template v-slot:top>
          <v-toolbar flat>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogProduct" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  เพิ่มรายการสินค้าสินค้า
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
<!--                      <v-col cols="12" sm="12" md="12">-->
<!--                        <v-select-->
<!--                          :items="[1,5,10,20,50,100,500,1000]"-->
<!--                          label="สินค้าน้อยกว่า">-->

<!--                        </v-select>-->
<!--                      </v-col>-->
<!--                      <v-col cols="12" sm="12" md="12">-->
<!--                        <v-radio-group label="ประเภทสินค้า">-->
<!--                          <v-radio label="ออนไลน์"></v-radio>-->
<!--                          <v-radio label="ภายใน"></v-radio>-->
<!--                        </v-radio-group>-->
<!--                      </v-col>-->

                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="product_id"
                          label="ค้นหาสินค้า"
                          item-value="id"
                          item-text="product_name"
                          :items="purchase_products">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="selectProduct">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
<!--          <v-icon small class="mr-2" @click="editItem(item)">-->
<!--            {{ icons.mdiPencil }}-->
<!--          </v-icon>-->
          <v-icon small @click="deleteItem(item)">
            {{ icons.mdiDelete }}
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
      <p class="text-end mb-0 mt-3 font-weight-bold">
        ยอดรวม <span class="font-weight-regular ms-5">{{ editedItem.sub_total }}</span>
      </p>
      <p class="text-end mb-0 font-weight-bold">
        ยอดเงินสุทธิ <span class="font-weight-regular ms-5">{{ editedItem.total }}</span>
      </p>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <p class="mb-0">หมายเหตุเอกสาร</p>
        </v-col>
        <v-col cols="12" sm="6" md="8">
          <p @click="showTextarea" class="mb-0 pTagDynamic">เพิ่มหมายเหตุ</p>
          <v-textarea v-if="textAreaContent" outlined hide-details label="เพิ่มหมายเหตุ" :value="note"></v-textarea>
        </v-col>
      </v-row>
      <!-- จบสินค้า -->

      <hr class="mt-3 mb-3" />

      <!-- เอกสาร และโน้ต-->
      <h3>เอกสารแนบ และโน้ต</h3>
      <p class="mb-0">เพิ่มเอกสาร หรือข้อความแนบสำหรับใบสั่งซื้อนี้</p>
      <v-row class="mt-2">
        <v-col cols="12" sm="6" md="4">
          <h4 class="mb-0 text-end">โน้ต</h4>
        </v-col>
        <v-col cols="12" sm="6" md="8">
          <v-textarea
            v-model="editedItem.note"
            outlined hide-details label="โน้ตอื่น ๆ เพื่อใช้ในบริษัท" :value="noteFile"></v-textarea>
<!--          round-->
          <v-btn color="primary" class="text-none mt-3" depressed :loading="isSelecting" @click="onButtonClick">
            <v-icon left>
              {{ icons.mdiCloudUpload }}
            </v-icon>
            {{ buttonText }}
          </v-btn>
          <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
        </v-col>
      </v-row>
      <!-- จบเอกสาร และโน๊ต -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMagnify, mdiPencil, mdiDelete, mdiCloudUpload } from '@mdi/js'
import RevenueDepartment from './RevenueDepartment.vue'
import Datepicker from 'vue2-datepicker'
import instance_payment from '../../../services/payment'
import instance_purchase_order from '../../../services/purchaseOrder'
import instance_pickup from "@/services/purchaseOrder";

export default {
  props: ['data','payments','close','getPurchaseOrder','purchase_products','purchases'],
  components: { Datepicker, RevenueDepartment },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiCloudUpload,
      },
    }
  },
  data() {
    return {
      // payments : [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      radioGroup: 1,
      radioGroup2: 1,
      nameSupplier: '',
      product_id: '',
      address: '',
      office: '',
      contactPerson: '',
      emailPerson: '',
      projects: '',
      telPerson: null,
      numberTax: null,
      dataRadio: ['สินค้า', 'จ้างบริการ/ค่าใช้จ่าย', 'สินทรัพย์'],
      buyForm: ['บริษัท / ห้างร้าน', 'บุคคลทั่วไป'],
      showData: false,
      currency: ['THB', 'เพิ่มเติม'],
      credit: ['ไม่มี', '7 วัน', '15 วัน', '30 วัน', '45 วัน', '60 วัน', '90 วัน', 'ระบุเอง'],
      tax: ['ไม่มีภาษี', 'แยกภาษี 7%', 'รวมภาษี 7%', 'ยื่น ภพ.36'],
      discount: ['ไม่มีส่วนลด', 'ส่วนลดรวม', 'ส่วนลดแยกตามสินค้า'],
      dialog: false,
      dialogDelete: false,
      dialogProduct: false,
      total: 120,
      netAmount: 120,
      textAreaContent: false,
      note: '',
      noteFile: '',
      defaultButtonText: 'ไฟล์แนบ',
      selectedFile: null,
      isSelecting: false,
      headers: [
        // {
        //   text: 'สินค้า',
        //   align: 'start',
        //   sortable: false,
        //   value: 'nameProduct',
        // },
        { text: '#', value: 'index' },
        { text: 'ประเภท', value: 'type' },
        { text: 'รายการ', value: 'product.product_name' },
        { text: 'ราคาหน่วย', value: 'price' },
        { text: 'จำนวน', value: 'amount' },
        { text: 'ส่วนลด', value: 'discount' },
        { text: 'จำนวนเงิน', value: 'total' },
        { text: 'แก้ไข', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        purchase_order_type : 2,
        purchase_on: '',
        updated_at : '',
        date : '',
        supplier_name : '',
        contact_name : '',
        address : '',
        payment_id : '',
        project_name : '',
        employee : '',
        employee_id : '',
        note : '',
        tax_id: '',
        discount : '',
        payment_type : '',
        status : '',
        vat : '',
        sub_total : '',
        total : '',
        details : []
      },
      defaultItem: {
        purchase_on: '',
        updated_at : '',
        date : '',
        supplier_name : '',
        contact_name : '',
        address : '',
        payment_id : '',
        project_name : '',
        employee : '',
        employee_id : '',
        note : '',
        tax_id: '',
        discount : '',
        payment_type : '',
        status : '',
        vat : '',
        sub_total : '',
        total : '',
        details : []
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },
  methods: {
    getPurchaseOrderDetail(id){
      instance_purchase_order.getPurchaseOrderDetail(id)
        .then(res => {
          this.editedItem.details = res.data.purchase_order_details;
          console.log(this.editedItem.details,888)
      }).catch(err => {

      })
    },
    clickShowData() {
      this.showData = !this.showData
    },
    initialize() {
      this.desserts = [
        {
          nameProduct: 'Frozen Yogurt',
          quantity: 159,
          priceUnit: 6.0,
          price: 24,
        },
        {
          nameProduct: 'Ice cream sandwich',
          quantity: 237,
          priceUnit: 9.0,
          price: 37,
        },
        {
          nameProduct: 'Eclair',
          quantity: 262,
          priceUnit: 16.0,
          price: 23,
        },
      ]
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      console.log(this.editedIndex,444)
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    // close() {
    //   this.dialog = false
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem)
    //     this.editedIndex = -1
    //   })
    // },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    showTextarea() {
      this.textAreaContent = !this.textAreaContent
    },
    selectProduct(){
      var tmp = {
        id : '',
        price : 0,
        product : this.product_id,
        product_id : this.product_id.product_id,
        type : 'All',
        purchase_order_id : '',
        amount : 0,
        discount : 0,
      }
      this.editedItem.details.push(tmp)
      this.dialogProduct = false;

    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
  },
  mounted() {
    // this.close();
    this.editedItem = this.data
    if (this.editedItem.id){
      this.getPurchaseOrderDetail(this.editedItem.id)

    }
  }
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.pTagDynamic {
  color: #8dbc8a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.pTagDynamic:hover {
  color: #728f70;
}
</style>
