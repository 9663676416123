<template>
  <v-card-text class="mt-4">
    <!-- ใบรับสินค้า -->
    <div class="d-flex justify-space-between">
      <p class="mb-0">ใบรับสินค้า: {{ mockReceipt }}</p>
      <p>{{ mockDayReceipt }}</p>
    </div>
    <!-- จบใบรับสินค้า -->
    <hr class="mb-3" />
    <!-- ชื่อที่อยู่ซัพพลายเออร์ -->
    <h3>ชื่อที่อยู่ซัพพลายเออร์</h3>
    <p>รายละเอียดข้อมูลการติดต่อซัพพลายเออร์</p>
    <v-row class="mt-1 mb-2">
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">ชื่อซัพพลายเออร์</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
        <p class="mb-0">{{ nameSupplier }}</p>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">ที่อยู่</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
        <p class="mb-0">{{ address }}</p>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">เลขประจำตัวผู้เสียภาษี</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
        <p class="mb-0">{{ numberTax }}</p>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">สำนักงาน/สาขาเลขที่</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
        <p class="mb-1">{{ branch }}</p>
        <p v-if="hideData" class="mb-0 pTagDynamic" @click="switchHide()">
          แสดงข้อมูล
        </p>
        <p v-if="!hideData" class="mb-0 pTagDynamic" @click="switchHide()">
          ซ่อนข้อมูล
        </p>
      </v-col>
    </v-row>
    <!-- จบชื่อที่อยู่ซัพพลายเออร์ -->

    <hr class="mb-3" />

    <!-- รายการสินค้าที่ได้รับ -->
    <div class="d-flex align-center justify-space-between">
      <h3>รายการสินค้าที่ได้รับ</h3>
      <v-dialog transition="dialog-top-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" text v-bind="attrs" v-on="on">เพิ่มโปรเจค</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>เพิ่มโปรเจค</v-toolbar>
            <v-card-text class="mt-3">
              <p class="mb-2">กรอกชื่อโปรเจกต์</p>
              <v-text-field
                v-model="projects"
                outlined
                dense
                hide-details
                label="ชื่อโปรเจกต์"
                placeholder="กรอกชื่อโปรเจกต์"
                class="mt-2 mb-3"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
    <v-data-table dense :headers="headers" :items="desserts" item-key="name" class="elevation-1"></v-data-table>
    <v-row class="mt-2 mb-2">
      <v-col sm="10" md="10" cols="6" class="pt-0 pb-1">
        <p class="text-end mb-0 font-weight-bold">ยอดรวม</p>
      </v-col>
      <v-col sm="2" md="2" cols="6" class="d-flex align-center pt-0 pb-1">
        <p class="font-weight-regular mb-0">{{ total }}</p>
      </v-col>

      <v-col sm="10" md="10" cols="6" class="pt-0 pb-1">
        <p class="text-end mb-0 font-weight-bold">ส่วนลดรวม</p>
      </v-col>
      <v-col sm="2" md="2" cols="6" class="d-flex align-center pt-0 pb-1">
        <p class="font-weight-regular mb-0">{{ discount }}</p>
      </v-col>

      <v-col sm="10" md="10" cols="6" class="pt-0 pb-1">
        <p class="text-end mb-0 font-weight-bold">ยอดหลังหักส่วนลด</p>
      </v-col>
      <v-col sm="2" md="2" cols="6" class="d-flex align-center pt-0 pb-1">
        <p class="font-weight-regular mb-0">{{ balanceDiscount }}</p>
      </v-col>

      <v-col sm="10" md="10" cols="6" class="pt-0 pb-1">
        <p class="text-end mb-0 font-weight-bold">ภาษีมูลค่าเพิ่ม 7%</p>
      </v-col>
      <v-col sm="2" md="2" cols="6" class="d-flex align-center pt-0 pb-1">
        <p class="font-weight-regular mb-0">{{ tax }}</p>
      </v-col>

      <v-col sm="10" md="10" cols="6" class="pt-0 pb-1">
        <p class="text-end mb-0 font-weight-bold">ยอดเงินสุทธิ</p>
      </v-col>
      <v-col sm="2" md="2" cols="6" class="d-flex align-center pt-0 pb-1">
        <p class="font-weight-regular mb-0">{{ netAmount }}</p>
      </v-col>
    </v-row>
    <!-- จบรายการสินค้าที่ได้รับ -->

    <hr class="mb-3" />

    <!-- ใบกำกับภาษี -->
    <h3>ใบกำกับภาษี</h3>
    <p>รายละเอียดในใยกำกับภาษี เพื่อใช้ยื่นภาษีซื้อ</p>
    <v-row class="mt-1 mb-2">
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">วันที่ในใบกำกับภาษี</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0 mb-2">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          hide-details
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="วันที่"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              class="textCase"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 mt-2">
        <h4 class="text-end">เลขที่ใบกำกับภาษี</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0 mt-2">
        <v-text-field
          v-model="numberBillTax"
          label="เลขที่ใบกำกับภาษี"
          type="text"
          outlined
          dense
          hide-details
          placeholder="เลขที่ใบกำกับภาษี"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 mt-2">
        <h4 class="text-end">ภาษีซื้อ</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0 mt-2">
        <p class="mb-0">{{ taxBuy }}</p>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 d-flex align-center justify-end">
        <h4 class="text-end">บันทึกภาษี</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
        <v-radio-group v-model="radioGroup">
          <v-radio v-for="n in dataRadio" :key="n" :label="`${n}`" :value="n"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">ใบกำกับภาษี</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
        <v-btn color="primary" class="text-none" round depressed :loading="isSelecting" @click="onButtonClick">
          <v-icon left>
            {{ icons.mdiCloudUpload }}
          </v-icon>
          {{ buttonText }}
        </v-btn>
        <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
      </v-col>
    </v-row>
    <!-- จบภาษีใบกำกับภาษี -->

    <hr class="mb-3 mt-5" />

    <!-- ค่าใช้จ่าย -->
    <div class="d-flex justify-space-between">
      <div>
        <h3>ค่าใช้จ่าย</h3>
        <p>เพิ่มต้นทุนค่าใช้จ่ายที่ใช้ในสินค้า</p>
      </div>
      <v-btn color="primary">เพิ่มค่าใช้จ่าย</v-btn>
    </div>
    <!-- จบค่าใช้จ่าย -->

    <hr class="mb-3" />

    <!-- เอกสารารรับสินค้า / บริการ -->
    <h3>เอกสารรับสินค้า / บริการ</h3>
    <p>เอกสารอ้างอิงในการวางบิลและชำระเงิน เพื่อใช้งานในการตรวจสอบและเป็นหลักฐานทางบัญชี</p>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">กำหนดชำระเงิน</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-1">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          hide-details
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="วันที่"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              class="textCase"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <!-- ที่อยู่ -->
      <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0 mt-3">
        <h4 class="text-end">โน้ต</h4>
      </v-col>
      <v-col md="8" sm="6" cols="12" class="pt-0 pb-0 mt-3">
        <v-textarea outlined hide-details label="เพิ่มที่อยู่" :value="address"></v-textarea>
      </v-col>
      <!-- ใบกำกับภาษี -->
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 mt-3">
        <h4 class="text-end">ใบกำกับภาษี</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0 mt-3">
        <v-btn color="primary" class="text-none" round depressed :loading="isSelecting_button2" @click="onButtonClick2">
          <v-icon left>
            {{ icons.mdiCloudUpload }}
          </v-icon>
          {{ buttonText }}
        </v-btn>
        <input ref="uploader" class="d-none" type="file" @change="onFileChanged2" />
      </v-col>
    </v-row>
    <!-- จบเอกสารารรับสินค้า / บริการ -->
  </v-card-text>
</template>

<script>
import { mdiMagnify, mdiCloudUpload } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiCloudUpload,
      },
    }
  },
  data() {
    return {
      mockReceipt: 'RI-00000002',
      mockDayReceipt: '01/01/2022',
      nameSupplier: 'บจก. A จำกัด',
      address: '9/99 หมู่ 9 ถนนเลขที่ 9',
      numberTax: '01909019909471',
      branch: 'สำนักงานใหญ่',
      hideData: true,
      total: 140000,
      discount: 0,
      balanceDiscount: 140000,
      tax: 9800,
      netAmount: 140000,
      projects: '',
      taxBuy: 9800,
      desserts: [
        {
          nameProduct: 'Frozen Yogurt',
          quantity: 159,
          priceUnit: 6.0,
          discount: 0,
          price: 24,
        },
        {
          nameProduct: 'Ice cream sandwich',
          quantity: 237,
          priceUnit: 9.0,
          discount: 0,
          price: 37,
        },
        {
          nameProduct: 'Eclair',
          quantity: 262,
          priceUnit: 16.0,
          discount: 0,
          price: 23,
        },
      ],
      headers: [
        {
          text: 'สินค้า',
          align: 'start',
          sortable: false,
          value: 'nameProduct',
        },
        { text: 'จำนวน', value: 'quantity' },
        { text: 'ราคาต่อหน่วย', value: 'priceUnit' },
        { text: 'ส่วนลด', value: 'discount' },
        { text: 'ราคารวม', value: 'price' },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      numberBillTax: 'RX54555901',
      dataRadio: ['ยื่นภาษีซื้อในเดือนล่าสุด', 'รอใช้สิทธิ', 'อื่น ๆ'],
      isSelecting: false,
      isSelecting_button2: false,
      buttonText: 'ไฟล์แนบ',
    }
  },
  methods: {
    switchHide() {
      this.hideData = !this.hideData
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onButtonClick2() {
      this.isSelecting_button2 = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting_button2 = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
    onFileChanged2(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.pTagDynamic {
  color: #8dbc8a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.pTagDynamic:hover {
  color: #728f70;
}
.textCase {
  max-width: 200px;
}
</style>
